import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceInventoryStatus'
})
export class DeviceInventoryStatusPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    const inventoryStatus = {
      11: {title: 'In stock', 'colorClass': 'text-success'},
      13: {title: 'Active', 'colorClass': 'text-primary'},
      18: {title: 'Holding', 'colorClass': 'text-gold'},
      12: {title: 'Pending', 'colorClass': 'text-orange'},
      14: {title: 'Defective', 'colorClass': 'text-danger'},
      15: {title: 'Returned to Vendor', 'colorClass': 'text-purple'},
      16: {title: 'Uninstalled', 'colorClass': 'text-gold'},
      17: {title: 'Pending return to inventory', 'colorClass': 'text-purple'},
      19: {title: 'Request for Termination', 'colorClass': 'text-purple'},
      20: {title: 'Terminated', 'colorClass': 'text-danger'},
      21: {title: 'Restock', 'colorClass': 'text-success'},
      22: {title: 'Pending Processing', 'colorClass': 'text-orange'},
    };

    return inventoryStatus[value];
  }

}
