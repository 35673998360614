import { environment } from './../../environments/environment';

const BASE_URL = environment.apiEndpoint;
const API_URL = BASE_URL + '/api';
// the below endpoints must be updated to the proper routes
const ADMIN_API_URL = API_URL + '/super_admin/v1';

export const API_ENDPOINTS = {
    update_password: BASE_URL + '/user/password',
    avatar: API_URL + '/avatar',
    zipcode: API_URL + '/zipcode/{zipcode}',
    ethnicity: API_URL + '/ethnicity',
    caretypes: API_URL + '/care-types',
    check_email: API_URL + '/check-email',
    getOtp: API_URL + '/file/generateOtp',
    downloadFile: API_URL + '/file/download',
    permission: API_URL + '/permission',
    states_lists: API_URL + '/lists/states',
    company_file_upload: ADMIN_API_URL + '/company/file-upload',
    company_logo_upload: ADMIN_API_URL + '/company/logo-upload',
    auth: {
        csrf_cookie: BASE_URL + '/sanctum/csrf-cookie',
        login: BASE_URL + '/super_admin/login',
        logout: BASE_URL + '/logout',
        forgot_password: BASE_URL + '/forgot-password',
        reset_password: BASE_URL + '/reset-password',
        set_password: API_URL + '/set-password',
        is_loggedin: API_URL + '/super_admin/is-loggedin',
    },
    admin: {
        me: ADMIN_API_URL + '/me',
        meBio: ADMIN_API_URL + '/me/details',
        updateBio: ADMIN_API_URL + '/me/details/update',
        notificationPreferenceUpdate: ADMIN_API_URL + '/notification-preferences',
        practices_list: ADMIN_API_URL + '/practices',
        permissions_list: ADMIN_API_URL + '/permissions',
        org_tree: ADMIN_API_URL + '/group/organizational-tree',
        admins: {
            list: ADMIN_API_URL + '/admins',
            store: ADMIN_API_URL + '/admins',
            detail: ADMIN_API_URL + '/admins/{id}',
            update: ADMIN_API_URL + '/admins/{id}',
            delete: ADMIN_API_URL + '/admins/{id}',
            status: ADMIN_API_URL + '/admins/{id}/status',
        },
        careteams: {
            list: ADMIN_API_URL + '/careteams',
            store: ADMIN_API_URL + '/careteams',
            detail: ADMIN_API_URL + '/careteams/{id}',
            update: ADMIN_API_URL + '/careteams/{id}',
            delete: ADMIN_API_URL + '/careteams/{id}',
            status: ADMIN_API_URL + '/careteams/{id}/status',
        },
        providers: {
            list: ADMIN_API_URL + '/providers',
            store: ADMIN_API_URL + '/providers',
            detail: ADMIN_API_URL + '/providers/{id}',
            update: ADMIN_API_URL + '/providers/{id}',
            delete: ADMIN_API_URL + '/providers/{id}',
            status: ADMIN_API_URL + '/providers/{id}/status',
        },
        company: {
            list: ADMIN_API_URL + '/company',
            store: ADMIN_API_URL + '/company',
            detail: ADMIN_API_URL + '/company/{id}',
            update: ADMIN_API_URL + '/company/{id}',
            delete: ADMIN_API_URL + '/company/{id}'
        },
        home_health: {
            list: ADMIN_API_URL + '/home-health',
            store: ADMIN_API_URL + '/home-health',
            detail: ADMIN_API_URL + '/home-health/{id}',
            update: ADMIN_API_URL + '/home-health/{id}',
            delete: ADMIN_API_URL + '/home-health/{id}',
            assign_list: ADMIN_API_URL + '/hh-agencies',
        },
        group: {
            list: ADMIN_API_URL + '/group',
            store: ADMIN_API_URL + '/group',
            detail: ADMIN_API_URL + '/group/{id}',
            update: ADMIN_API_URL + '/group/{id}',
            delete: ADMIN_API_URL + '/group/{id}',
            structure: ADMIN_API_URL + '/group/structure',
            org_tree: ADMIN_API_URL + '/group/organizational-tree',
        },
        user_access_roles: {
            list: ADMIN_API_URL + '/user-access-roles',
            store: ADMIN_API_URL + '/user-access-roles',
            detail: ADMIN_API_URL + '/user-access-roles/{id}',
            update: ADMIN_API_URL + '/user-access-roles/{id}',
            delete: ADMIN_API_URL + '/user-access-roles/{id}',
            physicians: ADMIN_API_URL + '/user-access-roles/physicians',
            home_healths: ADMIN_API_URL + '/user-access-roles/home-healths'
        },
        inventory_management: {
            device_types: ADMIN_API_URL + '/device-types',
            device_types_list: ADMIN_API_URL + '/device-types-list',
            device_update_status: ADMIN_API_URL + '/device-inventories-update-status',
            list: ADMIN_API_URL + '/device-inventories',
            create: ADMIN_API_URL + '/device-inventories',
            detail: ADMIN_API_URL + '/device-inventories/{id}',
            update: ADMIN_API_URL + '/device-inventories/{id}',
            inventory_totals: ADMIN_API_URL + '/inventory-management/totals',
            search_serial: ADMIN_API_URL + '/inventory-management/search',
            hologram_status: ADMIN_API_URL + '/devices/{serial}/hologram-info'
        },
    }
};
