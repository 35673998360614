import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private headerName = 'X-XSRF-TOKEN';

  private guestRoutes = [
    '',
    'authentication/login',
    'authentication/forgot-password',
    'authentication/reset-password',
    'authentication/file-download/:hash',
    'authentication/set-password'
  ];

  constructor(
    private tokenService: HttpXsrfTokenExtractor,
    private router: Router
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      withCredentials: true
    });

    request.headers.set('withCredentials', 'true');

    const token = this.tokenService.getToken();
    // Be careful not to overwrite an existing header of the same name.
    if (token !== null && !request.headers.has(this.headerName)) {
      request = request.clone({ headers: request.headers.set(this.headerName, token) });
    }

    return next.handle(request).pipe(tap(
      () => { },
      (error) => this.handleError(error)
    ));
  }

  // tslint:disable-next-line: typedef
  private handleError(error: any) {
    let baseUrl = '';
    if (this.router.url !== '') {
      const parsedUrl = this.router.parseUrl(this.router.url);
      baseUrl = parsedUrl.root.children.primary.segments.map(it => it.path).join('/');
    }

    if (error instanceof HttpErrorResponse) {
      if (baseUrl.includes('file-download')) {
          this.router.navigate([baseUrl]);
      } else {
        if (error.status === 401 && !this.guestRoutes.includes(baseUrl)) {
          // localStorage.setItem('isLoggedIn', 'false');
          return this.router.navigate(['/']);
        } else if (error.status === 403 && !this.guestRoutes.includes(baseUrl)) {
          return this.router.navigate(['/patients']);
        } else {
          return;
        }
      }
    }
  }
}
