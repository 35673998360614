<div class="header">
    <div class="logo logo-dark">
        <a href="">
            <img [src]="assets.logo" alt="Logo">
            <img class="logo-fold" [src]="assets.logo_fold" alt="Logo">
        </a>
    </div>
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-left">
            <li>
               Super Admin
            </li>
        </ul>
        <ul class="nav-right">
            <li>
                <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="appsList" [nzPlacement]="'bottomRight'">
                    <i nz-icon nzType="appstore" nzTheme="outline"></i>
                </a>
                <nz-dropdown-menu #appsList="nzDropdownMenu">
                    <div nz-menu class="pop-notification">
                        <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                            <nz-list-item *ngIf="('super_admin' | hasrole)">
                                <a>
                                    <div class="d-flex">
                                        <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                        <div class="m-l-15">
                                            <p class="m-b-0 text-dark">Super Admin</p>
                                        </div>
                                    </div>
                                </a>
                            </nz-list-item>
                            <nz-list-item *ngIf="('patient' | hasrole)">
                                <a [href]="APP_ENDPOINTS.patient" target="_blank">
                                    <div class="d-flex">
                                        <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                        <div class="m-l-15">
                                            <p class="m-b-0 text-dark">Patient</p>
                                        </div>
                                    </div>
                                </a>
                            </nz-list-item>
                            <nz-list-item *ngIf="('provider' | hasrole)">
                                <a [href]="APP_ENDPOINTS.provider" target="_blank">
                                    <div class="d-flex">
                                        <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                        <div class="m-l-15">
                                            <p class="m-b-0 text-dark">Provider</p>
                                        </div>
                                    </div>
                                </a>
                            </nz-list-item>
                            <nz-list-item *ngIf="('home_health' | hasrole)">
                                <a [href]="APP_ENDPOINTS.home_health" target="_blank">
                                    <div class="d-flex">
                                        <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                        <div class="m-l-15">
                                            <p class="m-b-0 text-dark">Home Health</p>
                                        </div>
                                    </div>
                                </a>
                            </nz-list-item>
                            <nz-list-item *ngIf="('care_team' | hasrole)">
                                <a [href]="APP_ENDPOINTS.careteam" target="_blank">
                                    <div class="d-flex">
                                        <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                        <div class="m-l-15">
                                            <p class="m-b-0 text-dark">Care Team</p>
                                        </div>
                                    </div>
                                </a>
                            </nz-list-item>
                            <nz-list-item *ngIf="('admin' | hasrole)">
                                <a [href]="APP_ENDPOINTS.admin" target="_blank">
                                    <div class="d-flex">
                                        <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                        <div class="m-l-15">
                                            <p class="m-b-0 text-dark">Admin</p>
                                        </div>
                                    </div>
                                </a>
                            </nz-list-item>
                            <nz-list-item *ngIf="('super_super_admin' | hasrole)">
                                <a [href]="APP_ENDPOINTS.super_super_admin" target="_blank">
                                    <div class="d-flex">
                                        <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                        <div class="m-l-15">
                                            <p class="m-b-0 text-dark">Super Super Admin</p>
                                        </div>
                                    </div>
                                </a>
                            </nz-list-item>
                        </nz-list>
                    </div>
                </nz-dropdown-menu>
            </li>
            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar style="background-color:#FEB2B2;" nzIcon="user" *ngIf="userProfile.avatar === null; else showProfileImage"></nz-avatar>
                    <ng-template #showProfileImage>
                        <nz-avatar nzIcon="user" [nzSrc]="userProfile.avatar" [nzAlt]="'profile picture'"></nz-avatar>
                    </ng-template>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" style="background-color:#FEB2B2;" nzIcon="user" *ngIf="userProfile.avatar === null; else showProfileImage"></nz-avatar>
                                <ng-template #showProfileImage>
                                    <nz-avatar nzSize="large" nzIcon="user" [nzSrc]="userProfile.avatar"></nz-avatar>
                                </ng-template>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">{{ userProfile?.full_name }}</p>
                                    <p class="m-b-0 opacity-07">{{ userProfile?.role }}</p>
                                </div>
                            </div>
                        </li>
                        <li nz-menu-item>
                            <a [routerLink]="['/account/profile']" class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Edit Profile</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li *ngIf="this.globals.bulkMessagePermission" nz-menu-item>
                            <a [routerLink]="['/bulk-message']" class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="send" theme="outline"></i>
                                    <span class="m-l-10">Send Message</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a [routerLink]="['/account/setting']" class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="setting" theme="outline"></i>
                                    <span class="m-l-10">Settings</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Logout</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
            <!-- <li>
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <app-quick-view></app-quick-view>
                </nz-drawer>
            </li> -->
        </ul>
    </div>
</div>

<nz-modal [(nzVisible)]="loggingOut" nzClosable="false" nzFooter="''">
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <ng-template nzModalContent>
        <nz-spin nzSimple [nzIndicator]="indicatorTemplate" class="mt-4 text-center"></nz-spin><br/>
        <div class="text-center">Logging you out.</div>
    </ng-template>
</nz-modal>