import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activityTypeStatus'
})
export class ActivityTypeStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const activityStatus = [
      {type: 'device_added', title: 'Device added to inventory', 'colorClass': 'text-success'},
      {type: 'device_for_check', title: 'Move Device for Checking', 'colorClass': 'text-gold'},
      {type: 'defective_device_returned', title: 'Move Device to Defective', 'colorClass': 'text-orange'},
      {type: 'device_returned_to_vendor', title: 'Return Device to Vendor', 'colorClass': 'text-danger'},
      {type: 'device_returned', title: 'Return Device to Inventory', 'colorClass': 'text-purple'},
      {type: 'device_returned_global', title: 'Return Device to Global Inventory', 'colorClass': 'text-gold'},
      {type: 'device_request_termination', title: 'Requested termination', 'colorClass': 'text-purple'},
      {type: 'device_terminate', title: 'Terminate device', 'colorClass': 'text-default'},
      {type: 'device_removed', title: 'Device removed from patient', 'colorClass': 'text-default'},
      {type: 'device_discharged', title: 'Device detached from patient', 'colorClass': 'text-default'},
      {type: 'device_refurbish', title: 'Device added to refurbish', 'colorClass': 'text-default'},
      {type: 'device_installed', title: 'Device Installed', 'colorClass': 'text-default'},
      {type: 'device_released', title: 'Device relessed to installer', 'colorClass': 'text-default'},
      {type: 'device_pending_processing', title: 'Device moved to Pending Processing', 'colorClass': 'text-default'},
    ];

    return activityStatus.find(x => x.type === value).title ?? 'Unknown';
  }

}
